<template>
  <Aggregate
    title="Reserves"
    v-model="reserves"
    :error-messages="errorMessages"
    :ItemConstructor="reserveFactory"
    :allow-edit="false"
    v-bind="$attrs"
    @change="$emit('change')"
  >
    <template #default="{ item, errors }">
      <InputYearly
        v-model="item.requirements"
        :error-messages="$utils.getIfExists(errors, ['requirements'])"
        :label="schema.requirements.title"
        :hint="schema.requirements.tooltip"
        :suffix="schema.requirements.unit"
        :num_years="num_years"
        type="number"
        fill
        :fill-value="schema.requirements.items.default"
        @change="$emit('change')"
      ></InputYearly>
      <v-text-field
        v-model.number="item.duration"
        :error-messages="$utils.getIfExists(errors, ['duration', 'messages'])"
        :label="schema.duration.title"
        :hint="schema.duration.tootltip"
        :suffix="schema.duration.unit"
        :min="schema.duration.minimum"
        type="number"
        @change="$emit('change')"
      ></v-text-field>
    </template>
  </Aggregate>
</template>

<script>
import { CaseModel, schemas } from "data-dictionary";
import Aggregate from "@/components/Aggregate";
import InputYearly from "@/components/InputYearly";

export default {
  name: "SystemReserve",
  components: {
    Aggregate,
    InputYearly,
  },
  props: {
    num_years: {
      type: Number,
      required: false,
    },
    reserves: {
      type: Array,
      required: true,
    },
    errorMessages: { type: Object, required: false },
  },
  methods: {
    reserveFactory: CaseModel.Reserve,
  },
  data() {
    return {
      schema: schemas.filter((v) => v.$id === "input.schema.json")[0].properties.reserves.items
        .properties,
    };
  },
};
</script>

<style></style>
