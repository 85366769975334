<template>
  <v-card class="ma-3">
    <v-toolbar flat color="white">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-if="allowEdit" @click="addItem">
        <v-icon>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="modifiedHeaders"
        :items="items"
        item-key="id"
        show-expand
        @change="saveItems"
      >
        <template #item.name="props">
          <v-text-field
            v-model="props.item.name"
            label="Name"
            single-line
            @change="$emit('change')"
          ></v-text-field>
        </template>

        <template #item.id="props">
          {{ items.indexOf(props.item) }}
        </template>

        <template v-for="(_, name) in $slots" #[name]>
          <slot :name="name" />
        </template>

        <!-- pass through scoped slots -->
        <template v-for="(_, name) in $scopedSlots" #[name]="props">
          <slot
            :name="name"
            v-bind="props"
            :allowEdit="allowEdit"
            :errors="$utils.getIfExists(errorMessages, [items.indexOf(props.item)])"
          />
        </template>

        <template #item.actions="props">
          <v-btn
            v-if="allowEdit"
            icon
            :disabled="!allowEdit || props.item.noDelete"
            @click="deleteItem(items.indexOf(props.item))"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>

        <template v-if="!noExpand" #expanded-item="{ item, headers }">
          <td :colspan="headers.length" class="pa-1">
            <!-- <v-card flat>
              <v-card-text> -->
            <slot
              name="default"
              :item="item"
              :errors="$utils.getIfExists(errorMessages, [items.indexOf(item)])"
            ></slot>
            <!-- </v-card-text>
            </v-card> -->
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Aggregate",
  model: {
    prop: "items",
  },
  components: {},
  props: {
    title: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    ItemConstructor: {
      type: Function,
      required: true,
    },
    allowEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    headers: {
      type: Array,
      required: false,
      default() {
        return [{ text: "Name", value: "name" }];
      },
    },
    noExpand: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: Object,
      required: false,
    },
  },
  computed: {
    hasExpand() {
      return this.$slots.default;
    },
    lastObjectIndex() {
      return this.items.length - 1;
    },
    modifiedHeaders() {
      const headers = [{ text: "Index", value: "id", sortable: false, width: 20 }, ...this.headers];
      if (!this.noExpand) {
        headers.unshift({
          value: "data-table-expand",
          sortable: false,
          width: 20,
        });
      }
      if (this.allowEdit) {
        headers.push({ text: "Actions", value: "actions", sortable: false });
      }
      return headers.map((e) => {
        if (e.width == null) return { ...e, width: 120 };
        return e;
      });
    },
  },
  methods: {
    addItem() {
      const obj = new this.ItemConstructor();
      obj.id = this.getNextId();

      this.items.push(obj);
      this.saveItems();
    },
    deleteItem(idx) {
      this.items.splice(idx, 1);
      this.saveItems();
    },
    saveItems() {
      this.$emit("input", this.items);
      this.$emit("change");
    },
    getNextId() {
      if (!this.items.length) return 1;
      return Math.max(...this.items.map((e) => e.id)) + 1;
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
